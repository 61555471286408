.answers {
  padding: 0 0 40px;
  position: relative;
  z-index: 9;
  font-family: 'Lato', sans-serif;

  @include mq(md){
    margin-top: auto;
    padding: 0 0 40px;
  }

  &__title {
    margin-top: 50px;
    font-size: 35px;
    font-weight: 400;
  }

  &__header {
    text-align: center;
    border-bottom: 1px solid $primary;
    padding-bottom: 30px;

    h1 {
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 20px;
      color: $black;
      line-height: 48px;

      @include mq(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      font-weight: 400;
    }
  }

  &__total {
    margin-top: 20px;
    font-size: 19px;
    font-weight: 400;
    color: #0E3D8A;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;

    @include mq(md) {
      font-size: 18px;
      margin-top: 10px;
    }
  }

  &__info {
    font-size: 22px;
    font-family: 'Jost', sans-serif;
    margin-top: 40px;
    margin-bottom: 0;
  }

  &__single {
    background-color: $white;
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;

    p{
      font-size: 18px;
      margin-left: 20px;

      @include mq(md) {
        font-size: 16px;
        margin-left: 0;
      }
    }
  }

  &__check {
    text-align: left;

    p {
      margin-bottom: .5rem;
    }

    .correct {
      color: #5D8B42;
    }

    .incorrect {
      color: #E15858;
    }

    strong {
      font-size: 25px;
    }
  }

  &__question {
    font-size: 26px !important;
    font-weight: 600;
    color: $black;
    margin-left: 0 !important;
    margin-bottom: 25px !important;

    @include mq(md) {
      font-size: 21px !important;
    }
  }

  &__class {
    .quiz__finishImage {
      z-index: 9;
    }
  }
}
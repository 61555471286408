.btn {
  border-radius: 33px;
  padding: 20px 45px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  transition: 0.2s;
  font-family: 'Poppins', sans-serif;

  @include mq(md) {
    padding: 14px 25px;
    font-size: 16px;
  }

  &__primary {
    background-color: $secondary;
    border: 1px solid $secondary;
    color: $white;
    min-width: 150px;

    img {
      transition: all .3s;
      width: 9px !important;
      height: 14px !important;
    }

    &:hover {
      color: $secondary;
      border: 1px solid $secondary;

      img {
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }
    }

    &:disabled {
      background-color: rgba($secondary, .75);
      color: rgba($white, .75);
    }

    &--quiz{
      &:hover{
        padding-left: 36px;
      }
    }

    &--outline {
      background-color: $white;
      border: 1px solid $secondary;
      color: $secondary;

      img {
        transition: all .2s;
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }

      &:hover {
        background-color: $secondary;
        color: $white;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__second {
    background-color: $primary !important;
    border: 1px solid $primary !important;
    color: $white !important;
    min-width: 150px;

    &:hover {
      color: $primary !important;
      border: 1px solid $primary;
      background-color: transparent !important;

      &:disabled {
        background-color: rgba($primary, .75) !important;
        color: rgba($white, .75) !important;
      }

      img {
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }
    }

    &--outline {
      background-color: transparent;
      border: 1px solid $primary;
      color: $primary;

      img {
        transition: all .2s;
        filter: invert(85%) sepia(132%) saturate(4711%) hue-rotate(195deg) brightness(60%) contrast(84%)
      }

      &:hover {
        background-color: $primary;
        color: $white;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__third {
    background-color: $third;
    border: 1px solid $third;
    color: $white;

    &:hover {
      background-color: $white;
      border: 1px solid $third;
      color: $third;
    }

    &:disabled {
      background-color: rgba($third, .75);
      color: rgba($white, .75);
    }

    &--outline {
      background-color: transparent;
      border: 2px solid $third;
      color: $third;

      img {
        transition: all .2s;
        filter: invert(15%) sepia(22%) saturate(4761%) hue-rotate(195deg) brightness(100%) contrast(94%);
      }

      &:hover {
        border: 2px solid $third;
        background-color: $white;
        color: $third;

        img {
          filter: invert(88%) sepia(89%) saturate(3%) hue-rotate(262deg) brightness(109%) contrast(97%);
        }
      }
    }
  }

  &__red {
    background-color: $red;
    border: 2px solid $red;
    color: $white;

    &:hover {
      background-color: transparent;
      border: 2px solid $red;
      color: $red;
    }

    &:disabled {
      background-color: rgba($red, .75);
      color: rgba($white, .75);
    }

    &--outline {
      background-color: transparent;
      border: 2px solid $red;
      color: $red;

      &:hover {
        border: 2px solid $red;
        background-color: $red;
        color: $white;
      }
    }
  }

  &__orange {
    background-color: $orange;
    border: 2px solid $orange;
    color: $white;
    font-weight: 600;

    &:hover {
      background-color: transparent;
      border: 2px solid $orange;
      color: $orange;
    }

    &:disabled {
      background-color: rgba($orange, .75);
      color: rgba($white, .75);
    }

    &--outline {
      background-color: transparent;
      border: 2px solid $orange;
      color: $orange;

      &:hover {
        border: 2px solid $orange;
        background-color: $orange;
        color: $white;
      }
    }
  }

  &__black {
    background-color: #707070;
    border: 1px solid #707070;
    color: #707070;

    img {
      transition: all .3s;
    }

    &:hover {
      background-color: transparent;
      color: $black;

      img {
        filter: invert(54%) sepia(19%) saturate(1469%) hue-rotate(40deg) brightness(106%) contrast(85%);
      }
    }

    &--outline {
      background-color: transparent;
      border: 1px solid #707070;
      color: #707070;

      img {
        transition: all .3s;
        filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(331deg) brightness(0%) contrast(105%);
      }

      &:hover {
        background-color: $black;
        color: $white;

        img {
          filter: invert(50%) sepia(5%) saturate(276%) hue-rotate(297deg) brightness(357%) contrast(100%);
        }
      }
    }
  }

  &__white {
    background-color: $white;
    color: $secondary;
    padding: 15px 36px;
    font-size: 14px !important;
    border: 1px solid $white;

    &:hover {
      border: 1px solid $white;
    }

    &--outline {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;

      &:hover {
        background-color: $white;
        color: $secondary;
      }
    }
  }

  &__yellow {
    background-color: $yellow;
    color: $white;
    padding: 15px 36px;
    border: 1px solid $yellow;

    &:hover {
      border: 1px solid $yellow;
      background-color: transparent;
      color: $yellow;
    }

    &--outline {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;

      &:hover {
        background-color: $white;
        color: $secondary;
      }
    }
  }
}
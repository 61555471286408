.footer {
    background-color: $white;
    padding: 40px 0;
    position: relative;
    z-index: 9;
    border-top: 40px solid #FAFAFA;

    &--dark {
        background-color: $secondary;
        color: $white;

        a, p {
            color: $white !important;
        }
    }

    &--link {
        font-weight: 300;
        font-family: 'Poppins', sans-serif;
    }

    @include mq(md) {
        overflow: hidden;
        padding: 30px 0;
    }

    &__image {
        height: 380px;
        position: absolute;
        top: -410px;
        left: -140px;
        z-index: 1;
    }

    &__link {
        color: $secondary;
        text-decoration: underline;

        &:hover {
            color: $primary;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 15px;
        color: #7A7A7A;
        position: relative;
        font-weight: 300;

        @include mq(md) {
            flex-direction: column;
            align-items: center;
        }

        a {
            color: $text;
            font-size: 14px;
            line-height: 15px;
            transition: all .3s;

            &:hover {
                color: $primary;
            }
        }

        p {
            color: $text;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 15px;
        }

        &--links {
            @include mq(md) {
                margin-top: 25px;
            }
            div {
                display: flex;
                flex-wrap: wrap;
                gap: 50px;

                @include mq(md) {
                    flex-direction: column;
                    gap: 15px;
                    text-align: center;
                }

                a {
                    font-weight: 300;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        gap: 25px;
        align-items: flex-start;
        color: $white;
        font-weight: 600;

        @include mq(md) {
            flex-direction: column;
            align-items: flex-start;

            a {
                margin-bottom: 10px;
            }
        }

        span {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;

            @include mq(md) {
                margin-bottom: 20px;
            }

            p {
                margin-top: 10px;
            }

            a {
                text-decoration: underline;
            }

            img {
                @include mq(md) {
                    margin-right: 10px;
                }
            }
        }
    }

    &__polpharmaLogo {
        width: 160px;
        height: auto;
        object-fit: contain;
    }

    &__iconWrap {
        width: 250px;

        @include mq(md) {
            display: none;
        }
    }

    &__icon {
        position: absolute;
        bottom: -41px;
        right: -55px;
    }

    &__copyright {
        margin-right: 100px;
        display: flex;
        align-items: center;

        @include mq(xxl, min) {
            margin-right: 0;
        }

        @include mq(md) {
            margin-top: 35px;
            margin-right: 0;
        }
    }

    &__brand {
        text-align: center;
        @include mq(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-bottom: 1px solid rgba($white, .08);
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    &__ml {
        color: #43629F;
        font-size: 17px;
        margin-top: 25px;

        @include mq(md) {
            margin-top: 15px;
        }
    }

    &__contact {
        color: $secondary;
        font-size: 21px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mq(md) {
            margin-top: 20px;
            z-index: 9;
        }

        a {
            color: $secondary;
            font-size: 16px;
            
            &.btn{
                color: $black;
                background-color: #fff;
                border-radius: 29px;
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 0;
        }

        img {
            margin-right: 15px;
        }

        &--first {
            padding-left: 40px;
            border-left: 1px solid rgba($white, .08);
            margin-left: 10px;

            @include mq(md) {
                padding-left: unset;
                border-left: none;
                margin-left: unset;
            }
        }

        &--last {
            padding-right: 40px;
            border-right: 1px solid rgba($white, .08);
            margin-right: 10px;

            @include mq(md) {
                padding-right: unset;
                border-right: none;
                margin-right: unset;
            }
        }
    }

    &__sil {
        color: $black;
        font-size: 14px;
        line-height: 16px;
        border-top: none;

        p {
            font-size: 11px;
            line-height: 13px;
            color: rgba($black, .55);
        }

        a {
            font-size: 15px;
            line-height: 20px;
            color: rgba($white, .35);
            text-decoration: underline;
        }
    }
}
.materialy {
  padding-top: 20px;
  min-height: 75vh;

  h2 {
    text-align: center;
    font-weight: 600;
    margin-top: 75px;
  }

  p {
    text-align: center;
    width: 50%;
    margin: 15px auto 30px;

    @include mq(md) {
      width: 85%;
    }
  }

  .buttons {
    display: flex;
    width: 55%;
    margin: 0 auto;
    justify-content: space-between;
    gap: 25px;

    @include mq(md) {
      width: 100%;
      flex-direction: column;
    }

    a {
      width: 100%;
    }
  }
}
.navbar {
  background-color: $white;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: all .4s;
  padding: 15px 0;
  width: 100%;

  @include mq(md) {
    padding: 5px 0;
    background-color: $white;
  }

  &-collapse {
    @include mq(md) {
      margin-top: 20px;
    }
  }

  &__logo {
    height: 50px;
    width: auto;
  }

  &__extra {
    font-size: 18px;
    border-left: 1px solid rgba($white, .31);
    padding-left: 25px;
    margin-left: 25px;
    font-family: 'Jost', sans-serif;
    padding-top: 10px;
    padding-bottom: 5px;
    color: $white;
    transition: all .3s;

    @include mq(md) {
      display: none;
    }
  }

  &__white {
    background-color: $white;
    background: $white;

    .navbar {
      &-nav {
        a {
          color: $black;
        }
      }

      &__logo {
        filter: unset;

        img{
          max-width: 90%;

          @include mq(md) {
            max-width: 100%;
          }
      }
      }

      &__extra {
        font-size: 18px;
        border-left: 1px solid rgba($black, .31);
        padding-left: 25px;
        margin-left: 25px;
        font-family: 'Jost', sans-serif;
        padding-top: 10px;
        padding-bottom: 5px;
        color: $black;

        @include mq(md) {
          display: none;
        }
      }
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }

    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }
  }

  &__half {
    background-color: transparent !important;
    background: transparent !important;

    .navbar {
      &-nav {
        a {
          color: $white;

          @include mq(md){
            color: $black;
          }
        }
      }

      &__logo {
        filter: unset;
      }

      &__extra {
        font-size: 18px;
        border-left: 1px solid rgba($black, .31);
        padding-left: 25px;
        margin-left: 25px;
        //font-family: 'Jost', sans-serif;
        padding-top: 10px;
        padding-bottom: 5px;
        color: $black;

        @include mq(md) {
          display: none;
        }
      }
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }

    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }

    &.scrolled {
      background: transparent !important;
    }
  }

  &-brand {
    @include mq(md) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 5px;
      max-width: 210px;
    }
  }

  &-nav {
    gap: 30px;

    @include mq(md) {
      gap: 10px;
    }

    a {
      font-size: 16px;
      font-weight: 400;
      color: $black;
      transition: all .4s;
      padding: 0 !important;
      font-family: 'Poppins', sans-serif;

      &:hover {
        color: rgba($primary, .85) !important;
        font-weight: 600;
      }
    }
  }

  .active {
    background-color: $white;
    padding-bottom: 20px;

    .navbar {
      &-nav {
        a {
          color: $black;

          @include mq(md){
            color: #000!important;
          }

          @include mq(xxl) {
            color: $white;
          }
        }
      }

      &__logo {
        filter: unset;
      }
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }

    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }
  }

  &.scrolled {
    background: $white;

    .navbar {
      &-nav {
        a {
          color: $black;

          @include mq(md) {
            color: $black;
          }

          &:hover {
            color: rgba($secondary, .85) !important;
          }
        }
      }

      &__logo {
        filter: unset;
      }

      &__extra {
        font-size: 18px;
        border-left: 1px solid rgba($black, .31);
        padding-left: 25px;
        margin-left: 25px;
        font-family: 'Jost', sans-serif;
        padding-top: 10px;
        padding-bottom: 5px;
        color: $white;

        @include mq(md) {
          display: none;
        }
      }
    }

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }

    .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
      background-color: $black;
      transition: all .3s;
    }
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: $black;
  transition: all .3s;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: $white;
  transition: all .3s;
}
.navbar + .show  {
  background-color: $white !important;
}
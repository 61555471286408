.nps .quiz__box {
  border-radius: 14px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .nps .quiz__box {
    padding: 20px 10px;
    width: 100%;
    margin-top: 0;
  }
  .nps__title{
    height: auto;
  }
}

.nps__top {
  font-size: 20px;
  margin-bottom: 60px;

  @include mq(md){
    padding: 0;
  }
}

.nps__top h1 {
  font-weight: 600;
  font-size: 32px;
  font-family: 'Lato', sans-serif;
  line-height: 36px;
  margin: 0;
  color: $text;

  @include mq(md) {
    font-size: 28px;
    line-height: 32px;
  }
}

.nps__top p {
  font-weight: 400;
  font-size: 26px;
  line-height: 44px;
  margin-top: 10px;
  color: $white;
}

.nps__boxesTop {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px;
  color: $primary;
  font-family: 'Poppins', sans-serif;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  @include mq(md) {
    &:first-of-type {
      margin-bottom: 5px;

      p {
        margin: 15px 0 0;
      }
    }

    &:last-of-type {
      padding-bottom: 25px;
      border-bottom: 1px solid rgba($black, .2);
      margin-bottom: 25px;
      text-align: right;

      p {
        margin: 0 0 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .nps__boxes {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .nps__boxesTop p {
    margin: 0 auto;
  }
}

.nps__boxes {
  display: flex;
  gap: 13px;
  margin: 10px auto 25px;
  padding-bottom: 60px;
  border-bottom: 1px solid $primary;

  @include mq(md) {
    border-bottom: none;
    padding-bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    gap: 5px;
  }
}

.nps__boxes .quiz__group {
  flex: 1 1 28%;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .nps__boxes .quiz__group {
    flex: 1 1 10%;
    align-items: flex-start;
  }
}

.nps__boxes .quiz__group input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.nps__boxes .quiz__group label {
  padding: 5px 6px 7px;
  width: 40px;
  height: 50px;
  color: $secondary;
  transition: all .4s;
  gap: 20px;
  text-align: center;
  background-color: $white;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 700;
}

.nps__boxes .quiz__group label span {
  font-size: 6rem;
  font-weight: 600;
}

.nps__boxes .quiz__group input:hover ~ label {
  background-color: $primary !important;
  color: #fff;
  border-color: $primary;
}

.nps__boxes .quiz__group input:checked ~ label {
  background-color: $primary !important;
  color: #fff;
  border-color: $primary;
}

.nps textarea {
  margin-top: 10px;
  width: 100%;
  background-color: $white;
  border: 1px solid #ECEDEF;
  font-size: 19px;
  padding: 25px 20px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
}

.nps textarea::placeholder {
  font-size: 19px;
}

.nps__spacer {
  border-bottom: 1px solid rgba($black, .19);
  width: 100%;
  margin: 35px 0 20px;
}

.nps__buttons {
  padding-top: 30px;
}

.nps__subtitle {
  color: rgba($black, .35);
  margin-bottom: 0;
  font-size: 19px;
}

.thx{
  height: 100%;
  min-height: 50vh;
  text-align: center;
  position: relative;

  @include mq(md){
    height: auto;
  }

  .nps__top {
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  h1 {
    font-size: 32px;
    line-height: 45px;
    font-weight: 600;
    color: #333333;
    font-family: 'Poppins', sans-serif;

    @include mq(md) {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  &__topText {
    color: $black !important;
    font-size: 26px !important;
    margin-top: 0 !important;
  }

  &__tall{
    height: 70vh;

    @include mq(md) {
      height: auto;
    }
  }

  &__spacer {
    width: 100%;
    margin: 60px auto 35px;
    height: 1px;
    background-color: #D8E1E6;

    @include mq(md) {
      width: 75%;
      margin: 30px auto;
    }
  }

  &__more {
    margin: 0 auto;

    h2 {
      margin-bottom: 45px;
      font-family: 'Poppins', sans-serif;
    }
  }

  &__authors {
    font-size: 18px;
    margin-bottom: 30px;
  }

  &__boxes {
    display: flex;
    gap: 30px;
    text-align: left;
    width: 70%;
    margin: 0 auto 80px;

    @include mq(md) {
      width: 100%;
      gap: 20px;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &__apps {
    position: absolute;
    bottom: 20px;
    width: 83%;
    display: flex;
    gap: 10px;
    align-items: center;

    @include mq(md) {
      position: unset;
      width: 70%;
    }

    a {
      position: unset !important;
      padding: 0 !important;
    }
  }

  &__box {
    flex: 1 1 23%;
    color: #092F78;
    font-size: 18px;
    font-weight: 600;
    background-color: $white;
    border-radius: 10px;

    @include mq(md) {
      flex: 1 1 100%;
    }

    .content {
      padding: 25px;
      height: 255px;
      position: relative;

      @include mq(md) {
        height: unset;
      }

      p {
        font-family: 'Poppins', sans-serif;
        color: $black;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;

        &:first-of-type {
          font-size: 23px;
          line-height: 26px;
          font-weight: 600;
        }
      }
    }

    a,
    button {
      padding: 10px 36px;
      position: absolute;
      bottom: 20px;

      @include mq(md) {
        position: unset;
        bottom: unset;
      }
    }
  }

  &__image {
    width: 100px;
    height: auto;
    margin-left: 10px;

    @include mq(md) {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
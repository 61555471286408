.game {
  background-image: url("../../dist/img/game_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  padding: 40px;
  margin-bottom: 20px;
  position: relative;

  @include mq(md) {
    padding: 20px 15px;
  }

  &__header {
    padding-left: 50px;
    margin-bottom: 25px;

    @include mq(md) {
      padding-left: 0;
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 10px;
    }

    p {
      font-size: 20px;
    }
  }

  &__przypis {
    font-size: 11px;
  }
}

.deck {
  background: linear-gradient(160deg, var(--color-gradient-1) 0%, var(--color-gradient-2) 100%);
  border-radius: 10px;
  box-shadow: 12px 15px 20px var(--color-shadow);
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  max-width: 610px;
  margin: 0 auto;

  @include mq(md) {
    gap: 15px;
    padding: 0;
    justify-content: space-evenly;
  }

  .square {
    background: $white;
    border-radius: 6px;
    box-shadow: 5px 2px 20px var(--color-shadow);
    border: 1px solid $primary;
    color: #ffffff;
    cursor: pointer;
    height: 120px;
    width: 120px;
    font-size: 0;

    @include mq(md) {
      height: 130px;
      width: 130px;
    }

    img {
      width: 0;
    }

    &.open {
      background: $white;
      cursor: default;

      img {
        width: 90px;
        height: auto;
      }
    }

    &.show {
      font-size: 33px;

      img {
        width: 90px;
        height: auto;
      }
    }

    &.match {
      background: $white;
      cursor: default;
      font-size: 33px;

      img {
        width: 90px;
        height: auto;
      }
    }
  }

  .li {
    align-content: center;
  }
}

.square-hover {
  box-shadow: 5px 10px 20px var(--color-shadow);
  transform: scale(1.02);
}

.score-panel {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 18px;
  text-transform: uppercase;
  color: #033E67;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  display: none !important;

  @include mq(md) {
    position: unset;
    font-size: 16px;
  }

  .moves,
  .timer {
    color: $primary;
    font-size: 26px;

    @include mq(md) {
      font-size: 22px;
    }
  }

  div {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(md) {
      align-items: center;
      margin: 0 auto 15px;
    }
  }
}

.square.animated.flipInY {
  animation-duration: 0.5s;
}
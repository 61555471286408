html,
body {
    min-height:100%;
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: $font-family-base;
    overflow-x: hidden;
    background: $white;
    position: relative;
}

.clear {
    &::before,
    &::after {
        content:' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

a,
button,
.btn {
    &:focus {
        box-shadow: none !important;
    }
}

.spacer {
    padding-top: 60px;
    border-bottom: 1px solid rgba($black, .15);
}

.page {
    position: relative;
    padding-top: 90px;
    height: 100%;
    background: rgb(237,248,254);
    background: linear-gradient(180deg, rgba(237,248,254,1) 0%, rgba(255,255,255,1) 100%);
    z-index: 9;

    @include mq(md) {
        padding-top: 80px;
        height: 100%;
    }

    &__steps {
        width: 100%;
        text-align: center;
        margin: 30px 0 40px;

        @include mq(md) {
            margin: 10px 0 20px;
        }
    }

    &--white {
        background: $white;
        height: 100%;
    }

    &--grey {
        background: #F9F9F9;
        height: 100%;
    }

    &--none {
        background: transparent;
        margin-bottom: 100px;
    }

    &--presentation {
        margin-bottom: 100px;
    }

    &--info {
        height: 100%;
        overflow: hidden;

        .home__background {
            bottom: -12%;

        }
    }

    &--quiz {
        background-image: url("../../dist/img/quiz-bg.webp");
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;

        .home__background {
            bottom: -30%;

            @include mq(xxl, min) {
                bottom: -48%;
            }
        }
    }

    &__textWhite {
        position: relative;
        z-index: 1;
        background-color: $white;
        padding: 40px 43px;
        border-radius: 20px;
        margin: 70px 0;
        color: $black;
        font-weight: 300;
        font-family: 'Poppins', sans-serif;

        @include mq(md) {
            padding: 25px;
        }

        h2 {
            color: $secondary;
            font-family: BloggerSans, sans-serif;
            margin-top: 47px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        a {
            color: $secondary;
            text-decoration: underline;
        }
    }

    .home__background {
        z-index: 0;
    }

    &__topImage {
        height: 250px;
        position: absolute;
        top: 10px;
        right: -60px;

        @include mq(md) {
            display: none;
        }
    }

    &__spacer {
        height: 35px;
        display: block;
    }

    &__znaczki {
        height: 132px;
        width: auto;
        object-fit: contain;
        margin-bottom: 30px;
    }

    &__operacyjny {
        height: 60px;
        width: auto;
        object-fit: contain;
        margin-bottom: 30px;
    }

    &__progress {
        display: flex;
        align-items: center;
        justify-items: center;
        width: fit-content;
        margin: 0 auto;
        position: relative;
        z-index: 9;

        &:first-of-type {
            margin-top: 15px;

            @include mq(md) {
                margin-top: 25px;
            }
        }

        .step {
            display: flex;
            align-items: center;
            padding: 12px 14px 8px;
            border-radius: 50%;
            background: #144089;
            color: $white;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;

            @include mq(md) {
                font-size: 12px;
                line-height: 12px;
                padding: 10px 12px 7px;
            }

            &:first-of-type {
                padding: 12px 16px 8px 14px;

                @include mq(md) {
                    padding: 9px 12px 8px;
                }
            }

            &--inactive {
                color: #C0CED6;
                border: 2px solid #C0CED6;
                background-color: transparent;
            }
        }

        &--extend {
            .line {
                width: 106px !important;

                @include mq(md) {
                    width: 60px !important;
                }

                &:nth-child(2) {
                    width: 112px !important;

                    @include mq(md) {
                        width: 60px !important;
                    }
                }

                &:nth-child(4) {
                    width: 100px !important;

                    @include mq(md) {
                        width: 60px !important;
                    }
                }
            }
        }

        .line {
            width: 177px;
            height: 3px;
            background-color: #C0CED6;

            @include mq(md) {
                width: 110px;
            }
        }

        &--text {
            width: 45%;
            justify-content: space-between;
            margin: 6px auto 50px;

            @include mq(xxl, min) {
                width: 38%;
            }

            @include mq(md) {
                width: 100%;
                margin: 5px auto 20px;
            }

            p {
                width: 100px;
                text-align: center;
                color: #144089;
                margin-bottom: 0;

                @include mq(md) {
                    font-size: 14px;
                }
            }

            .inactive {
                color: #C0CED6;
            }
        }
    }

    .accordion-item {
        margin-bottom: 10px !important;
        border: none !important;
        background-color: #F9F9F9;
        border-radius: 15px !important;
        font-family: 'Poppins', sans-serif !important;

        h2 {
            line-height: 20px;
        }
    }

    .accordion {
        padding: 25px 35px;
        background-color: $white;
        box-shadow: 0 0 30px 0 #CCDEE8;
        border-radius: 15px;

        @include mq(md) {
            padding: 20px;
        }
    }

    .accordion-header,
    .accordion-button {
        background-color: rgba($primary, .1);
        border: none !important;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 600;
        color: $secondary;
    }

    .accordion-body {
        background-color: rgba($primary, .1);
    }
}

.breadcrumbs {
    padding: 20px 0 50px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    gap: 10px;

    a,
    p {
        color: rgba($black, .3);
        display: flex;
        gap: 10px;
        transition: all .3s;

        &:hover {
            color: $primary;
        }
    }

    .item {
        &:after {
            display: block;
            content: '>';
            width: 6px;
            height: 12px;
        }

        &:last-child {
            color: $secondary;

            &:after {
                display: none;
            }
        }
    }
}

.modal-dialog {
    min-width: 700px;

    @include mq(md) {
        min-width: unset;
    }

    .modal-header {
        border-bottom: none;
    }

    .btn-close {
        background-color: $white;
        border: none;
        font-weight: 700;
    }
}

#zasady {
    margin-top: -100px;

    @include mq(md) {
        margin-top: -50px;
    }
}

.circle {
    background-color: transparent;
    height: 60px;
    width: 60px;
    display: block;
    border: 1px solid $primary;
    border-radius: 100px;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    animation-name:circle;
    animation-duration:2s;
    animation-timing-function: linear;
    animation-delay:0s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-play-state:running;

    @include mq(md) {
        left: 20px;
        right: unset;
        bottom: 70px;
    }
}
.fa-solid {
    font-size: 22px;
    color: $primary;
    bottom: 30px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -10px);
    animation-name:arrow;
    animation-duration:2s;
    animation-timing-function:linear;
    animation-delay:0s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-play-state:running;
}
@keyframes arrow
{
    0%   {bottom:0;}
    75% {bottom:35px;}
    100% {bottom:0;}
}
@keyframes circle
{
    0%  {height:60px;}
    10% {height: 60px;}
    50% {height: 70px;}
    75% {height: 80px;}
    90% {height: 70px;}
    100% {height: 60px;}
}